import axios from "axios";
import UserUtils from "../../../basecomp/auth/UserUtils";

const apiKey = "x-api-key";
const { handleLoginError, getTokenFromEmail } = UserUtils;

const getBEUrl = () => {
  const data = localStorage.getItem("permissionData");
  const isUAT = data !== undefined && data !== null && data.includes("feature_uat");
  let REACT_APP_BE_URL = isUAT ? process.env.REACT_APP_BE_URL2 : process.env.REACT_APP_BE_URL;
  return REACT_APP_BE_URL;
}

const responseHandling = (response) => {
  console.log('ggg');

    if (response === undefined) {
      throw new Error("No response");
    }
    if (response.status >= 200 && response.status <= 204) {
      if (response.data !== undefined && response.data.status !== undefined && response.data.status === 204) {
        throw new Error(response.data.message);
      } else {
        return response;
      }
    }
  }

const materialCountedPostApiCall = async (url, formData) => {
    let response;
    try {
      const token = getTokenFromEmail();
      response = await axios.post(`${getBEUrl()}${url}`, formData, {
        headers: {
            [apiKey]: token
        },
        responseType: 'blob', 
      });
  console.log(response,'ggg');

    } catch (error) {
  console.log(error.message,'ggg');
      
      handleLoginError(error);
    }
    return responseHandling(response);
  };

export default materialCountedPostApiCall;