import React from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

const CButton = ({
  cId,
  cText,
  cSrc,
  cTooltipText,
  cDisabled,
  cOnClick,
  cLink,
  cFullWidth,
}) => {
  const handleClick = () => {
    if (cOnClick !== undefined) {
      cOnClick();
    }
  };

  return (
    <Tooltip title={cTooltipText} arrow>
      <span>
        {cText !== undefined ? (
          <Button
            id={cId}
            aria-label={cTooltipText}
            disabled={cDisabled}
            onClick={handleClick}
            sx={{
              backgroundColor: "#052660",
              color: "#FFFFFF",
              borderRadius: "5px",
              padding: "10px",
              "&:hover": {
                backgroundColor: "#031d47", 
              },
              "&:disabled": {
                backgroundColor: "#9e9e9e",
                color: "#FFFFFF",
              },
            }}
            fullWidth={cFullWidth}
          >
            {cText}
          </Button>
        ) : cSrc !== undefined ? (
          <IconButton
            id={cId}
            aria-label={cTooltipText}
            disabled={cDisabled}
            onClick={handleClick}
            sx={{
              backgroundColor: "#052660",
              color: "#FFFFFF",
              borderRadius: "5px",
              padding: "8px",
              "&:hover": {
                backgroundColor: "#031d47",
              },
              "&:disabled": {
                backgroundColor: "#9e9e9e", 
                color: "#FFFFFF",
              },
            }}
          >
            {cSrc}
          </IconButton>
        ) : null}
      </span>
    </Tooltip>
  );
};

export default CButton;
