import React from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const CountingConfirmation = ({ CText, TotalOK, TotalNOK, terminal_id, storage_bin, storage_loc }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(
      `/warehouse/whs-location-info?terminal_id=${terminal_id}&storage_bin=${storage_bin}&storage_loc=${storage_loc}`
    );
  };

  const totalMaterials = TotalOK + TotalNOK;
  const okPercentage = ((TotalOK / totalMaterials) * 100).toFixed(2);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.81)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 2000,
      }}
    >
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          borderRadius: "18px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          width: "90%",
          maxWidth: "420px",
          textAlign: "center",
          overflowY: "auto",
          maxHeight: "90%",
        }}
      >
        <button
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "20px",
            right: "10px",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          <CloseIcon style={{ fontSize: "30px" }} />
        </button>

        <InfoIcon
          style={{
            fontSize: "50px",
            color: "#052660",
            margin: "60px 30px 0",
          }}
        />
        <p
          style={{
            fontSize: "18px",
            color: "#333",
            lineHeight: "35px",
            fontWeight: "300",
            padding: "0 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {CText} <TaskAltIcon style={{ color: "#6CBE29", fontSize: "22px" }} />
        </p>

        <div
          style={{
            display: "table",
            margin: "20px auto",
            textAlign: "left",
            padding: "0 10px",
            width: "80%",
            maxWidth: "420px",
            fontWeight: "300",
          }}
        >
          <div style={{ display: "table-row" }}>
            <span style={{ display: "table-cell", padding: "10px 0" }}>
              Total OK Counted Materials
            </span>
            <span style={{ display: "table-cell",padding: "0 5px" }}>: </span>
            <span style={{ display: "table-cell" }}>{TotalOK}</span>
          </div>

          <div style={{ display: "table-row" }}>
            <span style={{ display: "table-cell", padding: "10px 0" }}>
              Total NOK Counted Materials
            </span>
            <span style={{ display: "table-cell",padding: "0 5px" }}>: </span>
            <span style={{ display: "table-cell" }}>{TotalNOK}</span>
          </div>

          <div style={{ display: "table-row" }}>
            <span style={{ display: "table-cell", padding: "10px 0" }}>
              Percentage of OK Results
            </span>
            <span style={{ display: "table-cell",padding: "0 5px" }}>: </span>
            <span style={{ display: "table-cell" }}>{okPercentage}%</span>
          </div>
        </div>

        <button
          onClick={handleClose}
          style={{
            background: "none",
            border: "none",
            width: "100%",
            fontSize: "20px",
            cursor: "pointer",
            height: "50px",
            backgroundColor: "#052660",
            color: "#fff",
            borderRadius: "0 0 10px 10px",
            marginTop: "20px",
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CountingConfirmation;
