import { useState, useEffect } from "react";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import {
  uploadFile,
  getCatalogueTerminal,
  deleteApi,
  updateApi,
  downloadSample,
} from "../model/Dcatalogue";
import { CatKeys } from "../../assets/VarConstants";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const DCatalogueTerminalVM = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const { handleToastOpen } = useToast();

  let tableDataGlobal = {};
  let isTableEdit = false;

  const handleDeleteClick = (row) => {
    setRowToDelete(row);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (rowToDelete) {
      await deleteStickerData(rowToDelete);
    }
    setDeleteModalOpen(false);
    setRowToDelete(null);
  };

  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
    setRowToDelete(null);
  };

  const handleTableEditClick = (key) => (value) => {
    tableDataGlobal[key] = value;
  };

  const handleEditClick = async (data, index) => {
    if (data.tableMode === "E") {
      try {
        const response = await updateApi(
          tableDataGlobal.equipment_number,
          tableDataGlobal.catalogue_id,
          tableDataGlobal.catalogue_name,
          tableDataGlobal.terminal_id
        );
        const updatedData = response.data;
        isTableEdit = false;
        setTableData((prevDCatData) => {
          const updatedDCatData = [...prevDCatData];
          updatedDCatData[index].tableMode = "T";
          updatedDCatData[index].equipment_type = updatedData.equipment_type;
          updatedDCatData[index].equipment_number =
            updatedData.equipment_number;
          updatedDCatData[index].catalogue_id = updatedData.catalogue_id;
          updatedDCatData[index].catalogue_name = updatedData.catalogue_name;
          updatedDCatData[index].terminal_id = updatedData.terminal_id;
          return updatedDCatData;
        });
      } catch (error) {
        handleToastOpen("error", error.message);
      }
    } else {
      if (isTableEdit === false) {
        isTableEdit = false;
        const updatedDCatData = { ...data };
        updatedDCatData.tableMode = "E";
        tableDataGlobal = updatedDCatData;
        setTableData((prevDCatData) => {
          const updatedDCatData = [...prevDCatData];
          updatedDCatData[index].tableMode = "E";
          return updatedDCatData;
        });
      }
    }
  };

  const deleteStickerData = async (actualRow) => {
    try {
      setIsLoading(true);
      const response = await deleteApi(actualRow);

      handleToastOpen("success", response.data.message);

      isTableEdit = false;

      tableDataGlobal = {};

      await fetchData();
      setIsLoading(false);
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  };

  async function fetchData() {
    try {
      const catData = await getCatalogueTerminal();
      const updatedStickers = catData.map((obj) => ({
        ...obj,
        tableMode: "T",
      }));
      setTableData(updatedStickers);
    } catch (error) {
      setTableData([]);
      console.error(error.message);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const infoHeadData = [
    "Equipment Type",
    "Equipment ID",
    "Catalogue ID",
    "Catalogue Name",
    "Terminal",
    <div style={{ marginLeft: "8px" }}>
      <EditIcon />
    </div>,
    <div style={{ marginLeft: "8px" }}>
      <DeleteIcon />
    </div>,
  ];

  const [infoRowData, setInfoRowData] = useState([
    {
      value: CatKeys.EQUIPMENT_TYPE,
      type: "T",
    },
    {
      value: CatKeys.EQUIPMENT_ID,
      type: "T",
    },
    {
      value: CatKeys.CATALOGUE_ID,
      type: "M",
      actualType: (actualRow) => actualRow.tableMode,
      actionV: (value) => handleTableEditClick(CatKeys.CATALOGUE_ID)(value),
    },
    {
      value: CatKeys.CATALOGUE_NAME,
      type: "M",
      actualType: (actualRow) => actualRow.tableMode,
      actionV: (value) => handleTableEditClick(CatKeys.CATALOGUE_NAME)(value),
    },

    {
      value: CatKeys.TERMINAL,
      type: "T",
    },
    {
      value: (actualRow) =>
        actualRow.tableMode === "T" ? <EditIcon /> : <SaveIcon />,
      tooltipText: (actualRow) =>
        actualRow.tableMode === "T" ? "Edit" : "Save",
      type: "IC",
      actionV: (actualRow, index) => handleEditClick(actualRow, index),
    },
    {
      value: <DeleteIcon />,
      tooltipText: "Delete",
      type: "I",
      actionV: (actualRow) => handleDeleteClick(actualRow), // Changed to show confirmation first
    },
  ]);

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      const response = await downloadSample();
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `catalogue.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsLoading(false);
      handleToastOpen("success", "File Downloaded Successfully");
    } catch (error) {
      handleToastOpen("error", "File download Unsuccessful");
    }
  };

  const handleFileChange = (files) => {
    setSelectedFile(files);
  };

  const handleUpload = async () => {
    try {
      if (selectedFile === null) {
        handleToastOpen("error", "Please select any file!");
        return;
      }

      setIsLoading(true);
      const response = await uploadFile(selectedFile);
      handleToastOpen("success", response.data.message);
      setIsLoading(false);
      if (response.data.message) {
        window.location.reload();
      }
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  };

  return {
    selectedFile,
    handleFileChange,
    handleDownload,
    handleUpload,
    tableData,
    handleToastOpen,
    infoHeadData,
    infoRowData,
    isLoading,
    deleteModalOpen,
    handleDeleteConfirm,
    handleDeleteCancel,
  };
};

export default DCatalogueTerminalVM;
