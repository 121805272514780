import React, { useEffect, useState } from "react";
import Routing from "./Routing";
// import { CToast, Header, Footer, UserUtils, Services, LoginView, useToast } from "fe_comp_base";
import CToast from "../basecomp/components/CToast";
import { useToast } from "../basecomp/components/provider/ToastProvider";
import Header from "../basecomp/main/Header";
import Footer from "../basecomp/main/Footer";
import Services from "../basecomp/main/Services";
import UserUtils from "../basecomp/auth/UserUtils";
import LoginView from "../basecomp/auth/view/LoginView";
import { useLocation, useNavigate, Link } from "react-router-dom";
import ConasLogo from "./assets/conas_logo.png";
import ConasLogoSmall from "./assets/logo512.png";
import BgImg from "./assets/portbg_blur.png";
import warehouseimg from "../home/assets/warehouse.png";
import dcatimg from "../home/assets/d-catalogue.png";
import fuelimg from "../home/assets/f.png";
import glnimg from "../home/assets/gln.jpg";
import tariffimg from "../home/assets/tariff.png";
import materialimg from "./assets/matrequest.png";
import userimg from "./assets/user.jpg";
import xclupimg from "./assets/xclupload.jpg";
import devtoolimg from "./assets/devtools.png";
import monitor from './assets/users.jpg'
import PrintingView from "../label_print/src/view/PrintingView";
import UploadLabelView from "../label_print/src/view/UploadLabelView";
import BinLocationView from "../label_print/src/view/BinLocationView";
import WareHouseView from "../label_print/src/view/WarehouseLocation";
import ImageUploadView from "../image_upload/src/view/ImageUploadView";
import RegistrationDataView from "../gsone/src/view/RegistrationView";
import SparePartInfoView from "../gsone/src/view/SparePartInfoView";
import FuelAssetInfoView from "../gsone/src/view/FuelAssetView";
import HistoricalJob from "../d_catalogue/src/hist_jobs/view/HistoricalJob";
import ShoppingBasket from "../d_catalogue/src/sp_basket/view/ShoppingBasket";
import HistoricalJobDetails from "../d_catalogue/src/hist_jobs/view/HistoricalJobDetails";
import TermsAndConditions from "../visitor_mgmt/src/TermsAndConditions";
import Login from "../visitor_mgmt/src/Login";
import VisitorForm from "../visitor_mgmt/src/VisitorForm";
import VisitorDetails from "../visitor_mgmt/src/VisitorDetails";
import VisitorDetails2 from "../visitor_mgmt/src/VisitorDetails2";
import AdminDashboard from "../visitor_mgmt/src/AdminDashboard";
import ApproverDashboard from "../visitor_mgmt/src/ApproverDashboard";

import GLNView from "../gln/src/view/GLNView";
import Tariff from "../tariff/src/view/Tariff";

import ModifyUserView from "../user/src/view/ModifyUserView";
import ListUserView from "../user/src/view/ListUserView";
import PermissionView from "../user/src/view/PermissionView";
import MatRequestView from "../material_creation/src/view/MatRequestView";
import MatProcessView from "../material_creation/src/view/MatProcessView";
import MatRequestViewDemo from "../material_creation_demo/src/view/MatRequestView";
import MatProcessViewDemo from "../material_creation_demo/src/view/MatProcessView";
import UploadTemplatesViewDemo from "../material_creation_demo/src/view/UploadTemplatesView";
import UploadTemplatesView from "../material_creation/src/view/UploadTemplatesView";
// import PBIDashboardView from "../material_creation/src/view/PBIDashboardView";
import DCatalogueTerminal from "../dcatalogue/src/view/DcatalogueTerminal";
import MCTCommonFilesView from "../commonFiles/src/view/MCTCommonFilesView";
import Sample from "../devtool/src/view/Sample";
import TestAllCodeView from "../devtool/src/view/TestAllCodeView";
import AnySQLView from "../devtool/src/view/AnySQLView";

import ERPImplementationPage from "../home/src/ERPImplementation";
import HomePage from "../home/src//Home";
import MDMPage from "../home/src/MasterDataManagement";
import WarehousePage from "../home/src/WarehouseManagement";
import PowerBIPage from "../home/src/PowerBI";
import DCataloguePage from "../home/src/DCatalogue";
import FuelManagementPage from "../home/src/FuelManagement";
import DigitalLinkPage from "../home/src/DigitalLink";
import IndustryVerticlesPage from "../home/src/IndustryVerticles";
import AboutPage from "../home/src/About";
import ContactPage from "../home/src/Contact";
import New from "../home/src/New";
import Dashboard from "./dashboard/DashBoard";
import SessionHistory from "./dashboard/SessionHistory";
import WareHouseCounting from "../label_print/src/view/WarehouseCounting";

const AppContent = () => {
  const { handleLogin, handleLogout, getUserName, getInitials } = UserUtils;
  const { handleToastOpen } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const isHeader = !!pathName && pathName !== "/" && pathName !== "/login";
  const localData = localStorage.getItem("permissionData");
  const isProduct =
    pathName.includes("/warehouse/spare-part-info") ||
    pathName.includes("/warehouse/asset-info") ||
    pathName.includes("/warehouse/registration-asset") ||
    pathName.includes("/warehouse/whs-location-info");

  const handleStorageChange = () => {
    setUserName(getUserName());
    setUserInitials(getInitials());
  };
  window.addEventListener("cStorage", handleStorageChange);

  const services = [
    {
      key: "tool_user",
      title: "User Details",
      route: "/users/view-user",
      asset: userimg,
    },
    {
      key: "tool_material",
      title: "Material Request Tracker",
      route: "/material-creation/request-tracker",
      asset: materialimg,
    },
    {
      key: "tool_material",
      title: "Material Request Tracker Demo",
      route: "/material-creation-demo/request-tracker",
      asset: materialimg,
    },
    {
      key: "tool_dcat",
      title: "Catalogue Upload",
      route: "/d-cat-upload",
      asset: dcatimg,
    },
    {
      key: "tool_visitor",
      title: "Visitor Management",
      route: "/visitor-mgmt",
      asset: dcatimg,
    },
    {
      key: "tool_dev",
      title: "Excel Upload",
      route: "/common-upload",
      asset: xclupimg,
    },
    {
      key: "tool_dev",
      title: "Dev Tools",
      route: "/dev-tools/testcode",
      asset: devtoolimg,
    },
    {
      key: "monitor_dashboard",
      title: "Monitor Dashboard",
      route: "/dashboard/user",
      asset: monitor,
    },
    {
      key: "session History",
      title: "Session History",
      route: "/dashboard/user/history",
      asset: monitor,
    },
    {
      key: "tool_dcat",
      title: "D-Catalogue",
      route: "/d-catalogue/shopping-basket",
      asset: dcatimg,
    },
    {
      key: "tool_warehouse",
      title: "Warehouse Management",
      route: "/warehouse/label-printing",
      asset: warehouseimg,
    },
    {
      key: "tool_tariff",
      title: "Tariff Digital Calculator",
      route: "/tariff/main",
      asset: tariffimg,
    },
    {
      key: "tool_gln",
      title: "Global Location Number",
      route: "/global-location-data/main",
      asset: glnimg,
    },
    {
      key: "tool_fuel",
      title: "Fuel Management",
      route: "/",
      asset: fuelimg,
    },
  ];

  if (localData) {
    services.forEach((service) => {
      if (localData.includes(service.key)) {
        service.isEnabled = true;
      } else {
        service.isEnabled = false;
      }
    });
  }

  const appRouteMap = {
    "/home": {
      element: <HomePage />,
    },

    "/": {
      element: (
        <LoginView
          cLogo={ConasLogo}
          cBgImg={BgImg}
          handleToastOpen={() => handleToastOpen}
        />
      ),
    },
    "/login": {
      element: (
        <LoginView
          cLogo={ConasLogo}
          cBgImg={BgImg}
          handleToastOpen={() => handleToastOpen}
        />
      ),
    },
    "/services": {
      title: "Services",
      element: <Services services={services} linkComponent={Link} />,
    },
    "/ERP-Implementation": {
      // title: "ERP-Implementation",
      element: <ERPImplementationPage />,
    },
    "/MDM": {
      // title: "Master Data Management",
      element: <MDMPage />,
    },
    "/Warehouse": {
      // title: "Warehouse Management",
      element: <WarehousePage />,
    },
    "/PowerBI": {
      // title: "PowerBI Dashboards",
      element: <PowerBIPage />,
    },
    "/DCatalogue": {
      // title: "DCatalogue",
      element: <DCataloguePage />,
    },
    "/FuelManagement": {
      // title: "FuelManagement",
      element: <FuelManagementPage />,
    },
    "/DigitalLink": {
      // title: "DigitalLink",
      element: <DigitalLinkPage />,
    },
    "/IndustryVerticles": {
      // title: "IndustryVerticles",
      element: <IndustryVerticlesPage />,
    },
    "/About": {
      // title: "AboutPage",
      element: <AboutPage />,
    },
    "/Contact": {
      // title: "ContactPage",
      element: <ContactPage />,
    },
    "/New": {
      // title: "New",
      element: <New />,
    },
    "/d-catalogue/historical-job": {
      title: "Historical Job",
      element: <HistoricalJob />,
      isDrawer: true,
    },
    "/d-catalogue/historical-job-detail": {
      title: "Historical Job Detail",
      element: <HistoricalJobDetails />,
      isDrawer: false,
    },
    "/d-catalogue/shopping-basket": {
      title: "Shopping Basket",
      element: <ShoppingBasket />,
      isDrawer: true,
    },

    "/dashboard/user": {
      title: "User Dashboard",
      element: <Dashboard />,
      isDrawer: true,
    },
    "/dashboard/user/history": {
      title: "Session History",
      element: <SessionHistory />,
      isDrawer: true,
    },
    // "/warehouse/label-printing": {
    //   title: "Label Printing",
    //   element: <LabelPrinting />,
    //   isDrawer: true,
    // },
    // "/warehouse/label-upload": {
    //   title: "Upload Label Data",
    //   element: <UploadLabel />,
    //   isDrawer: true,
    // },
    "/warehouse/label-printing": {
      title: "Label Printing",
      element: <PrintingView />,
      isDrawer: true,
    },
    "/warehouse/label-upload": {
      title: "Bulk Upload Labels ",
      element: <UploadLabelView />,
      isDrawer: true,
    },
    "/warehouse/image-upload": {
      title: "Material Images Upload",
      element: <ImageUploadView />,
      isDrawer: true,
    },
    "/warehouse/whs-location-info": {
      title: "WHS Location Info",
      element: <WareHouseView />,
      isDrawer: false,
    },
    "/warehouse/whs-location-info/counting": {
      title: "Material Counting",
      element: <WareHouseCounting />,
      isDrawer: false,
    },
    "/warehouse/sap-bin-location": {
      title: "Bin Location Details",
      element: <BinLocationView />,
      isDrawer: true,
    },
    "/warehouse/registration-asset": {
      title: "Registration Data",
      element: <RegistrationDataView />,
      isDrawer: false,
    },
    "/warehouse/spare-part-info": {
      title: "Spare Part Info",
      element: <SparePartInfoView />,
      isDrawer: false,
    },
    "/warehouse/asset-info": {
      title: "Asset Info",
      element: <FuelAssetInfoView />,
      isDrawer: false,
    },

    "/global-location-data/main": {
      title: "Global Location Data",
      element: <GLNView />,
      isDrawer: false,
    },
    "/tariff/main": {
      title: "Tariff Digital Calculator",
      element: <Tariff />,
      isDrawer: false,
    },
    "/visitor-mgmt": {
      title: "Visitor Management",
      element: <TermsAndConditions />,
      isDrawer: false,
    },
    "/visitor-mgmt/login": {
      // title: "Visitor Management",
      element: <Login />,
      isDrawer: false,
    },
    "/visitor-mgmt/visitor-form": {
      // title: "Visitor Management",
      element: <VisitorForm />,
      isDrawer: false,
    },
    "/visitor-mgmt/approve-visitor/:id": {
      // title: "Visitor Management",
      element: <VisitorDetails />,
      isDrawer: false,
    },
    "/visitor-mgmt/visitor-details": {
      // title: "Visitor Management",
      element: <VisitorDetails2 />,
      isDrawer: false,
    },
    "/visitor-mgmt/visitor-details/:id": {
      // title: "Visitor Management",
      element: <VisitorDetails2 />,
      isDrawer: false,
    },
    "/visitor-mgmt/admin-dashboard": {
      // title: "Visitor Management",
      element: <AdminDashboard />,
      isDrawer: false,
    },
    "/visitor-mgmt/approver-dashboard": {
      // title: "Visitor Management",
      element: <ApproverDashboard />,
      isDrawer: false,
    },
    "/material-creation/request-tracker": {
      title: "Material Request Tracker",
      element: <MatRequestView />,
      isDrawer: true,
    },
    "/material-creation/process/:id": {
      title: "Material Request Process",
      element: <MatProcessView />,
      isDrawer: false,
    },
    "/material-creation-demo/request-tracker": {
      title: "Material Request Tracker Demo",
      element: <MatRequestViewDemo />,
      isDrawer: true,
    },
    "/material-creation-demo/process/:id": {
      title: "Material Request Process",
      element: <MatProcessViewDemo />,
      isDrawer: false,
    },
    "/material-creation-demo/upload-templates": {
      title: "Upload Templates Demo",
      element: <UploadTemplatesViewDemo />,
      isDrawer: true,
    },
    "/material-creation/pbidashboard": {
      title: "Material Creation Dashboard",
      // element: <PBIDashboardView /> ,
      isDrawer: true,
    },
    "/material-creation/upload-templates": {
      title: "Upload Templates",
      element: <UploadTemplatesView />,
      isDrawer: true,
    },
    "/users/update-user": {
      title: "Update User",
      element: <ModifyUserView />,
      isDrawer: false,
    },
    "/users/view-user": {
      title: "User Access Control",
      element: <ListUserView />,
      isDrawer: true,
    },
    "/users/edit-permission": {
      title: "Edit Permissions",
      element: <PermissionView />,
      isDrawer: true,
    },
    "/common-upload": {
      title: "Common Excel Upload",
      element: <MCTCommonFilesView />,
    },
    "/d-cat-upload": {
      title: "D-Catalogue Upload",
      element: <DCatalogueTerminal />,
    },
    "/dev-tools/sample": {
      title: "Sample Components",
      element: <Sample />,
      isDrawer: true,
    },
    "/dev-tools/testcode": {
      title: "Test Status Codes",
      element: <TestAllCodeView />,
      isDrawer: true,
    },
    "/dev-tools/anysql": {
      title: "Test Any SQL",
      element: <AnySQLView />,
      isDrawer: true,
    },
  };
  const drawerList = [
    "/warehouse",
    "/d-catalogue",
    "/users",
    "/material-creation",
    "/dev-tools",
  ];
  const [pageTitle, setPageTitle] = useState("");

  const [userName, setUserName] = useState(getUserName());
  const [userInitials, setUserInitials] = useState(getInitials());

  useEffect(() => {
    const pathname = location.pathname;
    let title = "";
    if (appRouteMap[pathname]) {
      title = appRouteMap[pathname].title;
    } else {
      title = "";
    }
    setPageTitle(title);
  }, [location.pathname]);

  return (
    <>
      {isHeader && (
        <Header
          cLogo={ConasLogo}
          cLogoSmall={ConasLogoSmall}
          handleLogin={handleLogin}
          handleLogout={handleLogout}
          cUserName={userName}
          cUserInitials={userInitials}
          pageTitle={pageTitle}
          appRouteMap={appRouteMap}
          drawerList={drawerList}
          pathName={pathName}
          navigate={navigate}
          linkComponent={Link}
        />
      )}
      <CToast />
      <Routing appRouteMap={appRouteMap} />
      {!isProduct && <Footer cLogo={ConasLogo} />}
    </>
  );
};

export default AppContent;
