import { useState, useEffect } from "react";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CButton from "../../../basecomp/components/CButton";
import {
  fetchWareHouseLoc,
  updateWareHouseLoc,
  deleteWareHouseLoc,
  getWareHouseLocPdf,
  getSampleWareHouseLocUploadFile,
  getAllWarehouseLocsMaterials,
} from "../model/WarehouseLocationModel";
import {
  WareHouseLocationKeys,
  PrintViewTypes,
} from "../../assets/VarConstants";
import { useLocation, useNavigate } from "react-router-dom";

const WarehouseLocationVM = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popUpBox, setPopUpBox] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const storage_bin =
    decodeURIComponent(searchParams.get("storage_bin")) || null;
  const storage_loc =
    decodeURIComponent(searchParams.get("storage_loc")) || null;
    const url_terminal =
    decodeURIComponent(searchParams.get("terminal_id")) || null;
  const terminal_id =localStorage.getItem("terminal")
  const { handleToastOpen } = useToast();

  const [warehouseLocationData, setWarehouseLocationData] = useState({
    [WareHouseLocationKeys.STORAGE_BIN_LOC_KEY]: "",
    [WareHouseLocationKeys.STORAGE_LOC_KEY]: "",
  });
  let isTableEdit = false;

  const [wareHouseData, setWareHouseData] = useState([]);
  const [wareHouseMaterialData, setWareHouseMaterialData] = useState([]);
  const [warehouseLoading, setWarehouseLoading] = useState(false);
  let tableWarehouseLocationDataGlobal = {};

  useEffect(() => {
    fetchAllWarehouseLocations();
  }, []);
  useEffect(() => {
    fetchAllWarehouseLocationMaterials();
  }, []);
  
  useEffect(() => {
    if (url_terminal != terminal_id) {
      setPopUpBox(true)
    }
  }, [url_terminal,terminal_id]);

  const fetchAllWarehouseLocations = async () => {
    setWarehouseLoading(true);
    try {
      const response = await fetchWareHouseLoc();
      const updatedWarehouseLocs = response.data.map((obj) => ({
        ...obj,
        tableMode: "T",
      }));
      setWareHouseData(updatedWarehouseLocs);
    } catch (error) {
      setWareHouseData([]);
    } finally {
      setWarehouseLoading(false);
    }
  };

  const handleTableEditClick = (key) => (value) => {
    tableWarehouseLocationDataGlobal[key] = value;
  };

  const handleEditClick = async (data, index) => {
    if (data.tableMode === "E") {
      try {
        const response = await updateWareHouseLoc(
          tableWarehouseLocationDataGlobal.id,
          tableWarehouseLocationDataGlobal.storage_bin_loc === null
            ? ""
            : tableWarehouseLocationDataGlobal.storage_bin_loc,
          tableWarehouseLocationDataGlobal.storage_loc === null
            ? ""
            : tableWarehouseLocationDataGlobal.storage_loc
        );
        const updatedData = response.data;
        isTableEdit = false;
        setWareHouseData((prevWareHouseData) => {
          const updatedWareHouseData = [...prevWareHouseData];
          updatedWareHouseData[index].tableMode = "T";
          updatedWareHouseData[index].bin_status = updatedData.bin_status;
          updatedWareHouseData[index].storage_bin_loc =
            updatedData.storage_bin_loc;
          updatedWareHouseData[index].storage_loc = updatedData.storage_loc;
          return updatedWareHouseData;
        });
      } catch (error) {
        handleToastOpen("error", error.message);
      }
    } else {
      if (isTableEdit === false) {
        isTableEdit = true; //state was not working, used a variable
        const updatedWareHouseData = { ...data };
        updatedWareHouseData.tableMode = "E";
        tableWarehouseLocationDataGlobal = updatedWareHouseData; //state was not working, used a variable
        setWareHouseData((prevWareHouseData) => {
          const updatedWareHouseData = [...prevWareHouseData];
          updatedWareHouseData[index].tableMode = "E";
          return updatedWareHouseData;
        });
      }
    }
  };

  const deleteWarehouseLocationData = async (id, type) => {
    setWarehouseLoading(true);
    try {
      const response = await deleteWareHouseLoc(id, type);
      handleToastOpen("success", response.data.message);
      isTableEdit = false;
      window.location.reload();
    } catch (error) {
      handleToastOpen("error", error.message);
    } finally {
      setWarehouseLoading(false);
    }
  };

  const getFormattedDateTime = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10);
    const currentTime = currentDate.toLocaleTimeString();
    return formattedDate + "_" + currentTime;
  };

  const pdfPrintView = async (id, isPrint, type) => {
    setWarehouseLoading(true);
    try {
      const response = await getWareHouseLocPdf(id, type);
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      if (isPrint) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `whs_location_info_${getFormattedDateTime()}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        await deleteWareHouseLoc(id, type);
        await fetchAllWarehouseLocations();
      } else {
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      }
    } catch (error) {
      handleToastOpen("error", error.message);
    } finally {
      setWarehouseLoading(false);
    }
  };

  const handleButtonClick = (key) => {
    if (key === "delete_all") {
      deleteWarehouseLocationData(undefined, PrintViewTypes.DELETE_ALL);
    } else if (key === "print_all") {
      pdfPrintView(undefined, true, PrintViewTypes.PRINT_VIEW_ALL);
    }
  };

  const infoHeadData = [
    "Storage Bin",
    "Storage Loc",
    "Edit",
    <CButton
      cSrc={<DeleteIcon />}
      cTooltipText="Delete All"
      cOnClick={() => handleButtonClick("delete_all")}
    />,
    "Preview",
    <CButton
      cSrc={<LocalPrintshopIcon />}
      cTooltipText="Print All"
      cOnClick={() => handleButtonClick("print_all")}
    />,
  ];

  const [infoRowData, setInfoRowData] = useState([
    {
      value: `storage_bin_loc`,
      tooltipText: (actualRow) =>
        actualRow.bin_status === 0
          ? "Storage bin having more than 10 digits"
          : "",
      statusType: "text",
      status: `bin_status`,
      type: "M",
      actualType: (actualRow) =>
        actualRow.is_storage_bin === 0 ? "T" : actualRow.tableMode,
      actionV: (value) =>
        handleTableEditClick(WareHouseLocationKeys.STORAGE_BIN_LOC_KEY)(value),
    },
    {
      value: `storage_loc`,
      statusType: "text",
      status: `bin_status`,
      type: "M",
      actualType: (actualRow) =>
        actualRow.is_storage_loc === 0 ? "T" : actualRow.tableMode,
      actionV: (value) =>
        handleTableEditClick(WareHouseLocationKeys.STORAGE_LOC_KEY)(value),
    },
    {
      value: (actualRow) =>
        actualRow.tableMode === "T" ? <EditIcon /> : <SaveIcon />,
      tooltipText: (actualRow) =>
        actualRow.tableMode === "T" ? "Edit" : "Save",
      type: "IC",
      actionV: (actualRow, index) => handleEditClick(actualRow, index),
    },
    {
      value: <DeleteIcon />,
      tooltipText: "Delete",
      type: "I",
      actionV: (actualRow) =>
        deleteWarehouseLocationData(actualRow.id, PrintViewTypes.PRINT_VIEW_ID),
    },
    {
      value: <PictureAsPdfIcon />,
      tooltipText: "View",
      status: `bin_status`,
      type: "I",
      actionV: (actualRow) =>
        pdfPrintView(actualRow.id, false, PrintViewTypes.PRINT_VIEW_ID),
    },
    {
      value: <LocalPrintshopIcon />,
      tooltipText: "Print",
      status: `bin_status`,
      type: "I",
      actionV: (actualRow) =>
        pdfPrintView(actualRow.id, true, PrintViewTypes.PRINT_VIEW_ID),
    },
  ]);

  const handleSampleWarehouseFileDownload = async () => {
    setWarehouseLoading(true);
    try {
      const response = await getSampleWareHouseLocUploadFile("warehouse");
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `SampleWarehouseLocationUpload.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleToastOpen("success", "File Downloaded Successfully");
    } catch (error) {
      handleToastOpen("error", "File download Unsuccessful");
    } finally {
      setWarehouseLoading(false);
    }
  };

  const fetchAllWarehouseLocationMaterials = async () => {
    setWarehouseLoading(true);
    try {
      const response = await getAllWarehouseLocsMaterials(
        url_terminal,
        storage_bin,
        storage_loc
      );
      setWareHouseMaterialData(response.data);
    } catch (error) {
      setWareHouseMaterialData([]);
    } finally {
      setWarehouseLoading(false);
    }
  };
  const infoMatHeadData = [
    "Storage Bin",
    "Material Number",
    "OHQ",
    "Material Description",
  ];

  const gsOneToolRedirectMethod = (material_no) => {
    navigate(`/warehouse/spare-part-info?material_no=${material_no}`);
  };

  const [infoMatRowData, setInfoMatRowData] = useState([
    {
      value: `bin_location`,
      tooltipText: "",
      actualType: (actualRow) => actualRow.tableMode,
      type: "M",
    },
    {
      value: `material_no`,
      tooltipText: "Click here to go to the Conas Resolver Tool",
      actualType: (actualRow) => actualRow.tableMode,
      type: "L",
      actionV: (actualRow) => gsOneToolRedirectMethod(actualRow.material_no),
    },

    {
      value: `ohqty`,
      tooltipText: "",
      actualType: (actualRow) => actualRow.tableMode,
      type: "M",
    },
    {
      value: `material_desc`,
      tooltipText: "",
      actualType: (actualRow) => actualRow.tableMode,
      type: "M",
    }
  ]);

  return {
    warehouseLocationData,
    wareHouseData,
    handleButtonClick,
    infoHeadData,
    infoRowData,
    handleSampleWarehouseFileDownload,
    fetchAllWarehouseLocations,
    infoMatHeadData,
    infoMatRowData,
    wareHouseMaterialData,
    storage_loc,
    terminal_id,
    warehouseLoading,
    gsOneToolRedirectMethod,
    storage_bin,
    popUpBox,
    url_terminal
  };
};

export default WarehouseLocationVM;
