import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";
import Logger from "../../../basecomp/base/Logger";
const currentUrl = window.location.origin;
const {
  axiosGetCallWithToken,
  axiosDeleteCallWithToken,
  axiosGetCallHeaderWithToken,
  axiosPutCallWithToken,
} = AxiosWrapper;

export const updateWareHouseLoc = async (id, storage_bin_loc, storage_loc) => {
  const response = await axiosPutCallWithToken(
      `/label_print/updateWarehouseLoc?id=${id}&storage_bin_loc=${storage_bin_loc}&storage_loc=${storage_loc}`
    );
  return response;
};

export const fetchWareHouseLoc = async () => {
  let response;
  try {
    response = await axiosGetCallWithToken(
      `/label_print/getAllWarehouseLocs`
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
          "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const deleteWareHouseLoc = async (id, type) => {
  let response;
  try {
    response = await axiosDeleteCallWithToken(
      `/label_print/deleteWarehouseLoc?id=${id}&type=${type}`
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
          "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const getWareHouseLocPdf = async (id, type) => {
  let response;
  console.log(id, type, "types");
  try {
    const idParam = id === undefined ? `` : `&id=${id}`;
    response = await axiosGetCallHeaderWithToken(
      `/label_print/getWarehousePdf?type=${type}&url=${currentUrl}${idParam}`,
      "application/pdf"
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
          "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const getSampleWareHouseLocUploadFile = async (type) => {
  let response;
  try {
    response = await axiosGetCallHeaderWithToken(
      `/label_print/getSampleCsv?type=${type}`,
      "text/csv"
    );
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
          "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};

export const getAllWarehouseLocsMaterials = async (
  terminal_id,
  storage_bin,
  storage_loc
) => {
  let response;
  try {
    if (
      terminal_id !== "null" &&
      storage_bin !== "null" &&
      storage_loc !== "null"
    ) {
      const effectiveTerminalId = (terminal_id == 1260 && storage_loc == 1120) ? 1121 : terminal_id;
      sessionStorage.setItem(
        "logingsoneurl",
        `/warehouse/whs-location-info?terminal_id=${effectiveTerminalId}&storage_bin=${storage_bin}&storage_loc=${storage_loc}`
      );
      response = await axiosGetCallWithToken(
        `/label_print/getAllWarehouseLocsMaterials?storage_bin=${storage_bin}`
      );
    }
  } catch (error) {
    let sendError = error;
    if (
      sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined
    ) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error(
        "Something unexpected happened." +
          "If the issue persists, please contact our support team for assistance."
      );
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (
      response.data !== undefined &&
      response.data.status !== undefined &&
      response.data.status === 204
    ) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
};
